import React, { useState, useEffect } from 'react';

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cursorClasses, setCursorClasses] = useState([]);

  const updateCursor = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const changeCursorOnHover = (e) => {
    const baseClass = 'customCursor';
    const targetingClass = 'targeting';
    let classesToAdd = [baseClass];
  
    for (const className of e.target.classList) {
      if (className.startsWith('target')) {
        const modifier = className.split('target')[1];
        if (modifier) {
          classesToAdd.push(`${targetingClass}${modifier}`);
        } else {
          classesToAdd.push(targetingClass);
        }
      }
    }
  
    setCursorClasses(classesToAdd);
  };
  

  useEffect(() => {
    window.addEventListener('mousemove', updateCursor);
    window.addEventListener('mouseover', changeCursorOnHover);

    return () => {
      window.removeEventListener('mousemove', updateCursor);
      window.removeEventListener('mouseover', changeCursorOnHover);
    };
  }, []);

  return (
    <div
      className={cursorClasses.join(' ')}
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    />
  );
};

export default CustomCursor;
